.App { 
    margin-top: 10px;
    padding: 10px;
    color: dimgray !important;
}

span {
    white-space: pre-wrap !important;
}

.scrolling {
    overflow-y: scroll;
}

h4 { 
    margin-top: 5px;
}

.header { 
    margin-bottom: 10px;
    font-size: 14pt;
}

.c3-xgrid-focus { display: none; }

.c3-legend-item {
    cursor:default !important;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.nowrap {
    white-space: nowrap;
  }

