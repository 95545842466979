.StatBubble {
    color: grey;
    padding: 5px;
    margin-bottom: 10px;
}

.StatBubble:hover {
    color: darkgrey;
    text-decoration: none;
}

.activeBubble {
    background-color: lightgrey;
}

.BubbleTitle {
    margin-bottom:5px;
}