#crosstab {
    color: 	#404040 !important;
}

.pvtTable {
    margin-left: auto;
    margin-right: auto;
    font-size: 9pt !important;
}

.pvtTable th {
    font-size: 9pt !important;
}

