.c3-axis {
    font-size : 10pt !important; 
}

.c3-chart-bar {
    opacity : 0.8 !important;
}

.name {
    white-space:pre-wrap;
}