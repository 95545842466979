.FieldSelect {
    margin-left: 10px;
    margin-right: 10px;
}
select { 
    width: 100%;
    margin-left:0px;
    margin-top: 10px !important;
    border-radius:5px;
    padding:3px;
}

option {
    padding-top:3px;
    padding-bottom:3px;
    border-radius: 2px;
    min-height:20px;
    font-size: 12pt;
    margin-top:4px;
    color:	#686868;
}

.slctDesc {
   height: 125px;
}

.slctDiv { 
    margin-bottom: 10px;
}